import {ResizeSensor} from 'css-element-queries';
import {action, computed, observable} from 'mobx';
import * as uuid from 'uuid';
import {IRectServer} from './interfaces';

export class RectServer implements IRectServer {

    public _viewportElement?: HTMLElement;
    public _viewportResizeSensor: ResizeSensor;

    public _windowEventElements = new Map <string, HTMLElement> ();
    public _windowElementSensors = new Map <string, ResizeSensor> ();

    @observable public viewportScrollDepth: number = 0;
    @observable public viewportScrollY: number = 0;
    @observable public viewportWidth: number = 0;
    @observable public viewportHeight: number = 0;

    @observable public windowWidth: number = 0;
    @observable public windowHeight: number = 0;
    @observable public windowOffsetX: number = 0;

    @observable public isResizingAutomatically = false;
    @observable public isResizingManually = false;

    @observable public isResizingElement = false;

    @observable public elementRects = new Map <string, ClientRect>();

    public _window?: Window;

    @computed public get isResizing() {
        return this.isResizingAutomatically || this.isResizingManually;
    }

    public constructor() {
        if (typeof window !== 'undefined') {
            this.bindToWindow(window);
        }
    }
    @action public bindElement = (element: HTMLElement, id?: string) => {

        if (typeof id === 'undefined') {
            id = element.getAttribute('id') || uuid.v4();
        }

        if (this._windowEventElements.has(id)) {
            if (this._windowEventElements.get(id) === element) {
                return id;
            }

            this.unbindElement(id);
        }

        element.setAttribute('id', id);

        this
            ._windowEventElements
            .set(id, element);

        this
            ._windowElementSensors
            .set(id, new ResizeSensor(element, () => {
                if (this.isResizing) {
                    return;
                }

                this.updateVisibleElementRects();
            }));

        this.runWindowEvents();

        return id;
    }

    public bindToWindow(_window: Window) {
        _window.addEventListener('resize', this.runWindowEvents.bind(this));
        _window.addEventListener('scroll', this.runWindowEvents.bind(this));

        this._window = _window;

        this.runWindowEvents();

        return this;
    }

    /* Cant use HTMLElement as param type in actions b/c problems running in node context
    https://github.com/tjoskar/ng-lazyload-image/issues/271 */
    @action public bindToViewport(_viewportElement: any) {
        _viewportElement.onscroll = this
            .runWindowEvents
            .bind(this);

        this._viewportElement = _viewportElement;
        this
            .elementRects
            .set('viewport', _viewportElement.getBoundingClientRect());

        this._viewportResizeSensor = new ResizeSensor(_viewportElement, this.runWindowEvents.bind(this));

        this.runWindowEvents();

        return this;
    }

    @action public updateVisibleElementRects = () => {
        this
            .getVisibleElementIds()
            .forEach((id) => {
                const element = this
                    ._windowEventElements
                    .get(id);

                if (!element) {
                    return;
                }

                this.updateElementRect(element, id);
            });
    }

    public getVisibleElementIds() {
        const viewportRect = this.getViewportRect();

        const ids: string[] = [];

        this
            .elementRects
            .forEach((rect, id) => {
                if (id === 'viewport') {
                    return;
                }

                if (rect.top > viewportRect.top + this.viewportHeight) {
                    return;
                }

                if (viewportRect.top > rect.top + rect.height) {
                    return;
                }

                ids.push(id);
            });

        return ids;
    }

    public getViewportRect(): ClientRect {
        const viewportRect = this
            .elementRects
            .get('viewport');

        if (viewportRect) {
            return viewportRect;
        } else {
            let width = 0;
            let height = 0;
            let top = 0;

            if (typeof this._window !== 'undefined') {
                top = this.windowOffsetX;
                width = this._window.innerWidth;
                height = this._window.innerHeight - top;
            }

            return {
                top,
                right: 0,
                bottom: 0,
                left: 0,
                width,
                height
            };
        }
    }

    public getElement = (id: string) => {
        return this._windowEventElements.get(id);
    }

    public getElementRect = (id: string) => {
        return this
            .elementRects
            .get(id) || null;
    }

    public getElementBoundRect(id: string) {

        const elementRect = this.getElementRect(id);

        if (elementRect && typeof this._viewportElement !== 'undefined') {

            const viewportRect = this
                .elementRects
                .get('viewport')as ClientRect;

            return this.transformElementRect(elementRect, viewportRect);

        }

        return elementRect;
    }

    public transformElementRect(rect: ClientRect, viewportRect: ClientRect) {

        if (rect.left >= viewportRect.left + this.viewportWidth) {
            return null;
        }

        if (rect.top >= viewportRect.top + this.viewportHeight) {
            return null;
        }

        if (viewportRect.left >= rect.left + rect.width) {
            return null;
        }

        if (viewportRect.top >= rect.top + rect.height) {
            return null;
        }

        const newRect: ClientRect = {
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: rect.height,
            right: rect.right,
            bottom: rect.bottom
        };

        if (viewportRect.left > newRect.left) {
            (newRect as any).width = newRect.width - viewportRect.left + newRect.left;
            newRect.left = viewportRect.left;
        }

        if (viewportRect.top > newRect.top) {
            (newRect as any).height = newRect.height - viewportRect.top + newRect.top;
            newRect.top = viewportRect.top;
        }

        if (newRect.left + newRect.width >= viewportRect.left + viewportRect.width) {
            (newRect as any).width = viewportRect.left + viewportRect.width - newRect.left;
        }

        if (newRect.top + newRect.height >= viewportRect.top + viewportRect.height) {
            (newRect as any).height = viewportRect.top + viewportRect.height - newRect.top;
        }

        return newRect as ClientRect;
    }

    @action
    public runWindowEvents() {

        if (typeof this._window === 'undefined') {
            return;
        }

        if (typeof this._viewportElement !== 'undefined') {

            this.viewportWidth = this._viewportElement.clientWidth;
            this.viewportHeight = this._viewportElement.clientHeight;
            this.viewportScrollY = this._viewportElement.scrollTop;

            this
                .elementRects
                .set('viewport', this._viewportElement.getBoundingClientRect());

        } else {

            this.viewportWidth = this._window.innerWidth;
            this.viewportHeight = this._window.innerHeight;
            this.viewportScrollY = this._window.scrollY;

            this
                .elementRects
                .delete('viewport');

        }

        this.windowWidth = this._window.innerWidth;
        this.windowHeight = this._window.innerHeight;

        this.updateElementRects();

        this.isResizingAutomatically = false;

        this
            ._window
            .dispatchEvent(new CustomEvent('windowEventsRun'));
    }

    @action public updateElementRects = () => {
        this
            ._windowEventElements
            .forEach(this.updateElementRect);
    }

    @action public updateElementRect = (element: HTMLElement, id: string) => {
        if (!(this._window as Window).document.getElementById(id)) {

            this.unbindElement(id);

        } else {

            const elementRect: ClientRect = element.getBoundingClientRect();

            this
                .elementRects
                .set(id, elementRect);
        }
    }

    @action public unbindElement = (id: string) => {
        if (!this._windowEventElements.has(id)) {
            throw new Error('Trying to unbind element that already has been unbound: ' + id);
        }

        if (this.elementRects.has(id)) {
            this.elementRects.delete(id);
        }

        this._windowEventElements.delete(id);

        if (this._windowElementSensors.has(id)) {
            const sensor = this._windowElementSensors.get(id);

            sensor!.detach(() => {
                this._windowElementSensors.delete(id);
            });
        }
    }
}
